import { Dropdown, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Menu, MenuButton, Tooltip, useTheme } from '@mui/joy';
import { ArrowDown2 } from 'iconsax-react';
import { matchPath, useLocation } from 'react-router-dom';
import Toggler from './Toggler';

interface Props extends React.PropsWithChildren {
  collapse: boolean;
  icon?: React.ReactNode;
  urls?: string[];
  title?: string;
};

const NavCollapse: React.FC<Props> = ({ collapse, children, icon, title, urls }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  let isSelected = false;
  if (urls != null) {
    for (let i = 0; i < urls.length; i++) {
      if (urls[i] != null && !!matchPath({ path: urls[i], end: false }, pathname)) {
        isSelected = true;
      }
    }
  }

  return (
    <ListItem nested>
      {!collapse && (
        <Toggler
          defaultExpanded={isSelected}
          renderToggle={({ open, setOpen }) => {
            return (
              <ListItemButton
                color={isSelected ? 'primary' : 'neutral'}
                onClick={() => setOpen(!open)}
                sx={{
                  px: 2.75,
                  py: 1.5,
                  opacity: isSelected ? 1 : .9,
                  fontWeight: isSelected ? 600 : 500,
                }}
              >
                {icon != null && (
                  <ListItemDecorator sx={{ opacity: isSelected ? 1 : .9 }}>
                    {icon}
                  </ListItemDecorator>
                )}
                <ListItemContent>
                  {title}
                </ListItemContent>
                <ArrowDown2
                  size={16}
                  color={theme.palette.neutral[500]}
                  style={{
                    transform: open ? 'rotate(180deg)' : 'none',
                    transition: '0.2s ease',
                  }}
                />
              </ListItemButton>
            );
          }}
        >
          <List>
            {children}
          </List>
        </Toggler>
      )}

      {collapse && (
        <Dropdown>
          <Tooltip
            title={title}
            variant="soft"
            placement="right"
          >
            <MenuButton
              slots={{ root: ListItemButton }}
              slotProps={{
                root: {
                  sx: {
                    px: 2,
                    py: 1.5,
                    opacity: isSelected ? 1 : .8,
                  },
                  selected: isSelected,
                  color: isSelected ? 'primary' : 'neutral'
                },
              }}
            >
              {icon}
            </MenuButton>
          </Tooltip>
          <Menu placement="right-start" sx={{ ml: 4 }}>
            {children}
          </Menu>
        </Dropdown>
      )}
    </ListItem>
  );
};

export default NavCollapse;
