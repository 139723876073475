import { Action, configureStore, ThunkAction, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import menu from './apps/menu';
import outlet from './apps/outlet';
import user from './apps/user';
import pelanggan from './apps/pelanggan';
import auth from './auth';
import ui from './ui';

export const store = configureStore({
  reducer: {
    auth,
    menu,
    outlet,
    user,
    pelanggan,
    ui,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type TypedDispatch = ThunkDispatch<RootState, any, UnknownAction>;
export type AppThunk<ReturnType = void> =
  ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
