import { Element, Setting2 } from 'iconsax-react';
import { menuPenjualan } from './penjualan';

export type IconVariant = 'Linear' | 'Outline' | 'Broken' | 'Bold' | 'Bulk' | 'TwoTone';

const variant: IconVariant = 'Bulk';
const size = 20;

export const menuItems: NavMenu[] = [
  {
    caption: 'Dashboard',
    to: '/dashboard',
    target: '_blank',
    icon: <Element variant={variant} size={size} />,
  },
  ...menuPenjualan(variant, size),
  { type: 'divider' },
  {
    caption: 'Pengaturan',
    to: '/pengaturan/outlet',
    icon: <Setting2 variant={variant} size={size} />,
  },
];
