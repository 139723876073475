import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getToken, getUserProfile } from '../../utils/localstorage';

interface AuthState {
  isLoggedIn: boolean;
  token?: string | null,
  user?: UserProfile | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
}

const getInitialState = (): AuthState => {
  const user = getUserProfile();
  const token = getToken();
  if (user == null || token == null || token === '') {
    return initialState;
  }

  return { ...initialState, user, token, isLoggedIn: true };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    setIsLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoggedIn = payload;
    },
    setToken: (state, { payload }: PayloadAction<string | null |  undefined>) => {
      state.token = payload;
    },
    setUser: (state, { payload }: PayloadAction<UserProfile | undefined | null>) => {
      state.user = payload;
    },
  },
});

export const { setIsLoggedIn, setToken, setUser } = authSlice.actions;
export default authSlice.reducer;
