import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Loadable from '../components/elements/Loadable/Loadable';
import DashboardLayout from '../components/layouts/DashboardLayout';

const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

const Pengaturan = Loadable(lazy(() => import('../pages/Pengaturan')));
const Outlet = Loadable(lazy(() => import('../pages/Pengaturan/subpages/Outlet')));
const AddOutlet = Loadable(lazy(() => import('../pages/Pengaturan/subpages/Outlet/AddOutlet')));
const EditOutlet = Loadable(lazy(() => import('../pages/Pengaturan/subpages/Outlet/EditOutlet')));
const Pengguna = Loadable(lazy(() => import('../pages/Pengaturan/subpages/Pengguna')));
const AddPengguna = Loadable(lazy(() => import('../pages/Pengaturan/subpages/Pengguna/AddPengguna')));
const EditPengguna = Loadable(lazy(() => import('../pages/Pengaturan/subpages/Pengguna/EditPengguna')));

const Menu = Loadable(lazy(() => import('../pages/apps/Menu')));
const MenuAdd = Loadable(lazy(() => import('../pages/apps/MenuAdd')));
const MenuEdit = Loadable(lazy(() => import('../pages/apps/MenuEdit')));
const Pelanggan = Loadable(lazy(() => import('../pages/apps/Pelanggan')));
const PelangganAdd = Loadable(lazy(() => import('../pages/apps/PelangganAdd')));
const PelangganEdit = Loadable(lazy(() => import('../pages/apps/PelangganEdit')));
const PelangganView = Loadable(lazy(() => import('../pages/apps/PelangganView')));
const Pesanan = Loadable(lazy(() => import('../pages/apps/outlet/Pesanan')));

const AppRoutes: RouteObject = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'pelanggan', element: <Pelanggan /> },
        { path: 'pelanggan/add', element: <PelangganAdd /> },
        { path: 'pelanggan/:id', element: <PelangganView /> },
        { path: 'pelanggan/:id/edit', element: <PelangganEdit /> },
        { path: 'pesanan', element: <Pesanan /> },
        { path: 'menu', element: <Menu /> },
        { path: 'menu/add', element: <MenuAdd /> },
        { path: 'menu/edit/:id', element: <MenuEdit /> },
        {
          path: 'pengaturan',
          element: <Pengaturan />,
          children: [
            { path: 'outlet', element: <Outlet /> },
            { path: 'pengguna', element: <Pengguna /> },
            { path: 'outlet/add', element: <AddOutlet /> },
            { path: 'outlet/:id', element: <EditOutlet /> },
            { path: 'pengguna/add', element: <AddPengguna /> },
            { path: 'pengguna/:id', element: <EditPengguna /> },
          ]
        },
      ],
    },
  ],
};

export default AppRoutes;
