import { Box, Drawer, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/system';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../../../stores';
import { setSidebarCollapse } from '../../../stores/ui';
import AuthGuard from '../AuthGuard';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';

const DashboardLayout = () => {
  const dispatch = useTypedDispatch();
  const theme = useTheme();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const { sidebarCollapse } = useTypedSelector(s => s.ui);

  useEffect(() => {
    if (downMD) {
      dispatch(setSidebarCollapse(true));
    }
  }, [dispatch, downMD]);

  return (
    <AuthGuard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {downMD ? (
          <Drawer open={!sidebarCollapse} onClose={() => dispatch(setSidebarCollapse(true))}>
            <Sidebar inDrawer />
          </Drawer>
        ) : (
          <Sidebar />
        )}

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Box
            sx={(theme) => ({
              position: 'sticky',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100,
              backdropFilter: 'blur(8px)',
              background: theme.palette.mode === 'light' ? 'rgba(247, 249, 251, .7)' : 'rgba(0, 0, 0, .8)',
            })}
          >
            <Header />
          </Box>
          <Box sx={{ flex: 1, px: { xs: 2, lg: 4 } }}>
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </AuthGuard>
  );
};

export default DashboardLayout;
