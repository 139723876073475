const PREFIX = 'tbk';

const USER = `${PREFIX}.user`;
const TOKEN = `${PREFIX}.token`;

export function clearLocalStorage(): void {
  localStorage.removeItem(USER);
  localStorage.removeItem(TOKEN);
}

export function getToken(): string | null {
  const savedToken = localStorage.getItem(TOKEN);
  if (savedToken == null) {
    return null;
  }

  try {
    return JSON.parse(atob(savedToken));
  } catch {
    return null;
  }
}

export function saveToken(token: string): void {
  const serializeToken = JSON.stringify(token);
  localStorage.setItem(TOKEN, btoa(serializeToken));
}


export function getUserProfile(): UserProfile | null {
  const savedUser = localStorage.getItem(USER);
  if (savedUser == null) {
    return null;
  }

  try {
    return JSON.parse(atob(savedUser));
  } catch {
    return null;
  }
}

export function saveUser(user: UserProfile): void {
  const serializeUser = JSON.stringify(user);
  localStorage.setItem(USER, btoa(serializeUser));
}
