import { Snackbar } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../stores';

interface Props {
  autoHideDuration?: number;
}

const AppToast: React.FC<Props> = ({ autoHideDuration }) => {
  const [open, setOpen] = useState(false);
  const { toast } = useTypedSelector(s => s.ui);

  useEffect(() => {
    if (toast.id != null && toast.id !== '') {
      setOpen(true);
    }
  }, [toast.id]);

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      open={open}
      variant="soft"
      color={toast.color}
      autoHideDuration={autoHideDuration == null ? 2000 : autoHideDuration}
      onClose={() => setOpen(false)}
      sx={{ textTransform: '' }}
    >
      {toast.message.length > 1 ? toast.message[0].toUpperCase() + toast.message.substring(1) : toast.message}
    </Snackbar>
  );
};

export default AppToast;
