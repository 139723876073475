import { MenuBoard, Profile2User, Reserve, ShoppingBag, ShoppingCart } from 'iconsax-react';
import { IconVariant } from '.';

export const menuPenjualan = (variant: IconVariant, size: number): NavMenu[] => {
  return [
    {
      caption: 'Penjualan',
      type: 'group',
      children:[
        {
          caption: 'Pesanan',
          icon: <ShoppingBag variant={variant} size={size} />,
          type: 'collapse',
          children: [
            {
              caption: 'Daftar Pesanan',
              to: '/pesanan',
              icon: <ShoppingCart variant={variant} size={size} />,
            },
            {
              caption: 'Dapur',
              icon: <Reserve variant={variant} size={size} />,
            },
          ],
        },
        {
          caption: 'Pelanggan',
          to: '/pelanggan',
          icon: <Profile2User variant={variant} size={size} />,
        },
        {
          caption: 'Daftar Menu',
          to: '/menu',
          icon: <MenuBoard variant={variant} size={size} />
        }
      ],
    },
  ];
};
