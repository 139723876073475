import { Box, Divider, List, Theme } from '@mui/joy';
import Logo from '../../../assets/Logo';
import LogoOnly from '../../../assets/LogoOnly';
import { menuItems } from '../../../menu-items';
import { useTypedSelector } from '../../../stores';
import NavCollapse from './NavCollapse';
import NavGroup from './NavGroup';
import NavItem from './NavItem';

function renderNavigation(collapse: boolean, menu: NavMenu[], level?: number) {
  return menu.map((m, i) => {
    switch (m.type) {
      case 'group':
        return (
          <NavGroup
            key={`${m.caption}.${i}`}
            collapse={collapse}
            title={m.caption}
          >
            {m.children != null && renderNavigation(collapse, m.children)}
          </NavGroup>
        );
      case 'collapse':
        const currentLevel = level == null ? 1 : level;
        const urls = m.children?.filter(v => v.to != null).map(v => v.to!);
        return (
          <NavCollapse
            key={`${m.caption}.${i}`}
            collapse={collapse}
            title={m.caption}
            icon={m.icon}
            urls={urls}
          >
            {m.children != null && renderNavigation(collapse, m.children, currentLevel + 1)}
          </NavCollapse>
        );
      case 'divider':
        return (
          <Divider key={`divider-${i}`} sx={{ mx: -3 }} />
        );
      default:
        return (
          <NavItem
            key={`${m.caption}.${i}`}
            collapse={collapse}
            content={m.caption}
            icon={m.icon}
            level={level}
            to={m.to}
          />
        );
    }
  });
}

interface Props {
  inDrawer?: boolean;
}

const Sidebar: React.FC<Props> = ({ inDrawer }) => {
  const { sidebarCollapse } = useTypedSelector(s => s.ui);

  const collapse = (() => {
    if (inDrawer || sidebarCollapse == null) {
      return false;
    }

    return sidebarCollapse;
  })();

  return (
    <Box
      sx={(theme) => ({
        borderRight: '1px solid #dedede',
        bgcolor: theme.palette.background.body,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      })}
    >
      <Box sx={{ px: 2, py: 4, display: 'flex', justifyContent: collapse ? 'center' : 'flex-start' }}>
        {collapse ? <LogoOnly size="lg" /> : <Logo size="lg" />}
      </Box>

      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <List
          sx={{
            gap: 1,
            '--ListItem-radius': (theme: Theme) => theme.vars.radius.md,
            transition: '0.2s ease',
            width: collapse ? 72 : 260,
            p: collapse ? 1 : undefined,
          }}
        >
          {renderNavigation(collapse, menuItems)}
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;
