import { Link, Stack, Typography } from '@mui/joy';

const Footer = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        px: { xs: 2, lg: 4 },
        pt: 2,
        pb: 1,
        mt: 2,
      }}
    >
      <Typography
        variant="plain"
        level="body-xs"
        sx={theme => ({ color: theme.palette.neutral[400] })}
      >
        &copy; Tumbuh Bersama Kreasi
      </Typography>

      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <Link
          href="https://yutumbuh.com"
          target="_blank"
          color="neutral"
          level="body-xs"
        >
          Home
        </Link>
        <Link
          href="https://yutumbuh.com/docs"
          target="_blank"
          color="neutral"
          level="body-xs"
        >
          Documentation
        </Link>
        <Link
          href="https://yutumbuh.com/support"
          target="_blank"
          color="neutral"
          level="body-xs"
        >
          Support
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
