import { Avatar, Box, Dropdown, IconButton, Input, ListItemDecorator, Menu, MenuButton, MenuItem, Stack, Typography, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/system';
import { HambergerMenu, Logout, SearchNormal1, User } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../../../stores';
import { signOut } from '../../../stores/auth/action';
import { setSidebarCollapse } from '../../../stores/ui';

const Header = () => {
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { sidebarCollapse } = useTypedSelector(s => s.ui);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        py: 2,
        px: { xs: 2, lg: 4 },
      }}
    >
      <Stack gap={2} direction="row">
        <IconButton onClick={() => dispatch(setSidebarCollapse(!sidebarCollapse))}>
          <HambergerMenu color={theme.palette.neutral[500]} />
        </IconButton>

        {!downSM && (
          <Input
            id="header-search"
            startDecorator={
              <SearchNormal1 color={theme.palette.neutral[500]} size={16} />
            }
            placeholder="Ctrl + K"
            variant="outlined"
          />
        )}
      </Stack>

      <Stack gap={2} direction="row">
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { sx: { borderRadius: 40, p: 0 }}}}
          >
            <Avatar color="primary" />
          </MenuButton>
          <Menu sx={{ minWidth: 200 }} placement="bottom-end">
            <MenuItem component="a" href="/profile">
              <ListItemDecorator>
                <User size={16} variant="Bulk" />
              </ListItemDecorator>
              <Typography>Profile</Typography>
            </MenuItem>

            <MenuItem onClick={() => dispatch(signOut(() => navigate('/sign-in')))}>
              <ListItemDecorator>
                <Logout size={16} variant="Bulk" />
              </ListItemDecorator>
              <Typography>Sign Out</Typography>
            </MenuItem>
          </Menu>
        </Dropdown>
      </Stack>
    </Box>
  );
};

export default Header;
