import { Outlet } from 'react-router-dom';
import GuestGuard from '../GuestGuard';

const AuthLayout: React.FC = () => {
  return (
    <GuestGuard>
      <Outlet />
    </GuestGuard>
  );
};

export default AuthLayout;
