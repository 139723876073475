import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
  categories: MenuKategori[];
  item?: MenuItem | null;
  items: MenuItem[];
}

const initialState: MenuState = {
  categories: [],
  items: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setCategories: (state, { payload }: PayloadAction<MenuKategori[]>) => {
      state.categories = payload;
    },
    setItem: (state, { payload }: PayloadAction<MenuItem | null | undefined>) => {
      state.item = payload;
    },
    setItems: (state, { payload }: PayloadAction<MenuItem[]>) => {
      state.items = payload;
    },
  },
});

export const { setCategories, setItem, setItems } = menuSlice.actions;
export default menuSlice.reducer;
