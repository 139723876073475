import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../../stores';
import { APP_DEFAULT_PATH } from '../../../config';

const GuestGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isLoggedIn } = useTypedSelector(s => s.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
        state: { from: '' },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return (
    <>
      {children}
    </>
  );
};

export default GuestGuard;
