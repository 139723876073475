import { LinearProgress, styled } from '@mui/joy';
import { ElementType, Suspense } from 'react';

const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': { marginTop: theme.spacing(2) }
}));

const Loader: React.FC = () => {
  return (
    <LoaderWrapper>
      <LinearProgress color="primary" sx={{ height: 2 }} />
    </LoaderWrapper>
  );
}


const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
