import { List, ListDivider, ListItem, ListSubheader } from '@mui/joy';

interface Props extends React.PropsWithChildren {
  collapse: boolean;
  title?: string;
}

const NavGroup: React.FC<Props> = ({ collapse, title, children }) => {
  return (
    <>
      {collapse && (
        <ListDivider sx={{ mx: -3 }} />
      )}
      <ListItem nested>
        {!collapse && (
          <ListSubheader sx={{ fontSize: '.7em', fontWeight: 700, letterSpacing: 0 }}>{title}</ListSubheader>
        )}
        <List>
          {children}
        </List>
      </ListItem>
    </>
  );
};

export default NavGroup;
