import { createBrowserRouter } from 'react-router-dom';
import Loadable from '../components/elements/Loadable/Loadable';
import { lazy } from 'react';
import LoginRoutes from './LoginRoutes';
import AppRoutes from './AppRoutes';

const LandingPage = Loadable(lazy(() => import('../pages/Landing')));

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
  },
  LoginRoutes,
  AppRoutes,
]);

export default router;
