import { ListItem, ListItemButton, listItemButtonClasses, ListItemDecorator, MenuItem, Tooltip, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/system';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../../stores';
import { setSidebarCollapse } from '../../../stores/ui';

interface Props {
  content: React.ReactNode| string;
  collapse: boolean;
  icon?: React.ReactNode;
  level?: number;
  to?: string;
}

const NavItem: React.FC<Props> = ({ content, icon, level, to, collapse }) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  
  const isSelected = to != null ? !!matchPath({ path: to, end: false }, pathname) : false;
  const ea = collapse ? 2 : 2.75;

  const pl = (() => {
    if (collapse) {
      return ea;
    }

    return level == null ? ea : ea * level;
  })();

  if (collapse && (level != null && level > 0)) {
    return (
      <MenuItem
        slots={{ root: ListItemButton }}
        selected={isSelected}
        color={isSelected ? 'primary' : 'neutral'}
        onClick={() => {
          if (to != null) {
            navigate(to);
          }
        }}
        sx={{ pl: ea, pr: 2 * ea, py: ea, opacity: isSelected ? 1 : .9 }}
      >
        {icon != null && (
          <ListItemDecorator  sx={{ opacity: isSelected ? 1 : .8 }}>
            {icon}
          </ListItemDecorator>
        )}
        {(!collapse || (level != null && level > 1)) && content}
      </MenuItem>
    );
  }

  return (
    <ListItem>
      <Tooltip
        title={content}
        variant="soft"
        placement="right"
        sx={{ display: collapse ? undefined : 'none' }}
      >
        <ListItemButton
          selected={isSelected}
          color={isSelected ? 'primary' : 'neutral'}
          onClick={() => {
            if (to != null) {
              navigate(to);
              if (downMD) {
                dispatch(setSidebarCollapse(true));
              }
            }
          }}
          sx={{
            pl,
            pr: ea,
            py: 1.5,
            opacity: .9,
            [`&.${listItemButtonClasses.selected}`]: {
              opacity: 1,
              fontWeight: 600,
            },
          }}
        >
          {icon != null && (
            <ListItemDecorator sx={{ opacity: isSelected ? 1 : .8 }}>
              {icon}
            </ListItemDecorator>
          )}
          {(!collapse || (level != null && level > 1)) && content}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

export default NavItem;
