import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PelangganState {
  daftarPelanggan: Pelanggan[];
  pelanggan?: Pelanggan | null;
  count: number;
}

const initialState: PelangganState = {
  count: 0,
  daftarPelanggan: [],
};

const pelangganSlice = createSlice({
  name: 'pelanggan',
  initialState,
  reducers: {
    setDaftarPelanggan: (state, { payload }: PayloadAction<Pelanggan[]>) => {
      state.daftarPelanggan = payload;
    },
    setPelangganCount: (state, { payload }: PayloadAction<number>) => {
      state.count = payload;
    },
    setPelanggan: (state, { payload }: PayloadAction<Pelanggan | null>) => {
      state.pelanggan = payload;
    },
  },
});

export const { setDaftarPelanggan, setPelangganCount, setPelanggan } = pelangganSlice.actions;
export default pelangganSlice.reducer;
