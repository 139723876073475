import { extendTheme, tabClasses } from '@mui/joy';

const theme = extendTheme({
  typography: {
    'body-lg': { fontSize: '1rem' },
    'body-md': { fontSize: '.9rem' },
    'body-sm': { fontSize: '.8rem' },
    'body-xs': { fontSize: '.7rem' },
    'title-sm': { fontWeight: 600 },
    'title-md': { fontWeight: 600 },
    'title-lg': { fontWeight: 600 },
  },
  components: {
    JoyTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${tabClasses.root}`]: {
            color: theme.palette.neutral[400],
          },
          [`&.${tabClasses.root}[aria-selected="true"]`]: {
            background: 'transparent',
          },
          [`&.${tabClasses.selected}`]: {
            color: theme.palette.primary[500],
            '&::after': {
              height: 2,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              bgcolor: theme.palette.primary[500],
            },
          },
        }),
      },
    },
    JoyTabs: {
      styleOverrides: {
        root: {
          background: 'transparent',
        }
      }
    },
    JoyTooltip: {
      defaultProps: {
        arrow: true,
        variant: 'soft',
      },
    },
  },
});

export default theme;
