import { CssBaseline, CssVarsProvider } from '@mui/joy';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import AppToast from './components/layouts/AppToast/AppToast';
import router from './routes';
import { store } from './stores';
import theme from './theme';

const App: React.FC = () => {
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <RouterProvider router={router} />
        <AppToast />
      </Provider>
    </CssVarsProvider>
  );
}

export default App;
