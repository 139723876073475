import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OutletState {
  outlets: Outlet[];
  outletCount: number;
  outlet?: Outlet | null;
}

const initialState: OutletState = {
  outlets: [],
  outletCount: 0,
};

const outletSlice = createSlice({
  name: 'outlet',
  initialState,
  reducers: {
    setOutlet: (state, { payload }: PayloadAction<Outlet | null | undefined>) => {
      state.outlet = payload;
    },
    setOutletCount: (state, { payload }: PayloadAction<number>) => {
      state.outletCount = payload;
    },
    setOutlets: (state, { payload }: PayloadAction<Outlet[]>) => {
      state.outlets = payload;
    },
  },
});

export const { setOutlet, setOutletCount, setOutlets } = outletSlice.actions;
export default outletSlice.reducer;
