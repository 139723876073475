import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthLayout from '../components/layouts/AuthLayout';
import Loadable from '../components/elements/Loadable/Loadable';

const SignIn = Loadable(lazy(() => import('../pages/Auth/SignIn')));
const SignUp = Loadable(lazy(() => import('../pages/Auth/SignUp')));
const ForgotPassword = Loadable(lazy(() => import('../pages/Auth/ForgotPassword')));
const CheckMail = Loadable(lazy(() => import('../pages/Auth/CheckMail')));

const LoginRoutes: RouteObject = {
  path: '/',
  element: <AuthLayout />,
  children: [
    { path: '/sign-in', element: <SignIn /> },
    { path: '/sign-up', element: <SignUp /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/check-email', element: <CheckMail /> },
  ],
};

export default LoginRoutes;
