import { Blur } from 'iconsax-react';

interface Props {
  size?: 'md' | 'lg';
}

const LogoOnly: React.FC<Props> = ({ size }) => {
  return (
    <Blur
      color="#ff6f00"
      size={size === 'lg' ? 32 : undefined}
      variant="Bulk"
      style={{ transform: 'rotate(15deg)' }}
    />
  )
};

export default LogoOnly;
